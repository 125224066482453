<template>
  <b-container fluid>
    <b-row>
      <b-col class="mt-auto" offset="3" md="6">
        <b-card class="mt-5" bg-variant="dark" body-text-variant="light" :title="$t('LOGIN_PAGE_TITLE') | capitalize">
          <b-card-text>
            <b-form @submit.prevent="login">
              <b-form-group id="username" :label="$t('USER_USERNAME_LABEL') | capitalize">
                <b-form-input v-model.trim="$v.form.username.$model" :state="state('username')"></b-form-input>
              </b-form-group>
              <b-form-group id="password" :label="$t('USER_PASSWORD_LABEL') | capitalize">
                <b-form-input type="password" v-model.trim="$v.form.password.$model"
                              :state="state('password')"></b-form-input>
              </b-form-group>

              <div class="d-block w-100">
                <b-form-group class="d-inline-block" style="width: 60% !important;" id="rememberMe">
                  <b-checkbox v-model="$v.form.rememberMe.$model" :state="state('rememberMe')">
                    <em>{{$t('REMEMBER_ME_LABEL') | capitalize}}</em>
                  </b-checkbox>
                </b-form-group>

                <div class="d-inline-block text-right align-top" style="width: 40% !important;">
                  <router-link class="ml-1" to="recovery">{{$t('PASSWORD_RECOVERY_LINK_LABEL') | capitalize}}</router-link>
                </div>
              </div>

              <b-button id="login-button" class="d-block w-100" :disabled="$v.form.$invalid || fgLogIn" variant="primary" type="submit">
                {{ $t('LOGIN_BUTTON_LABEL') | capitalize}}
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import Users from "@/users";
import Utils from "@/utils";
import {DBG, ProcessWithLoadingAndMessage} from "@/mixins";
import Constants from "@/constants";

export default {
  name: "Login",
  mixins: [DBG, ProcessWithLoadingAndMessage],
  validations: {
    form: {
      username: {required, minLength: minLength(4)},
      password: {required, minLength: minLength(4)},
      rememberMe: {}
    }
  },
  data() {
    return {
      form: {
        username: undefined,
        password: undefined,
        rememberMe: true
      },
      fgLogIn: false
    }
  },
  methods: {
    login: async function () {
      try {
        this.fgLogIn = true;
        await Users.login(this.form);
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('USER_LOGIN_ERROR_MESSAGE', {}, e);
      } finally {
        this.fgLogIn = false;
      }
    },
    setTestData() {
      this.form = {username: 'root', password: 'draxaigkofghwwyc', rememberMe: true};
    }
  },
  computed: {
    state() {
      return Utils.state(this.$v);
    }
  }
}
</script>

<style scoped>

</style>
